import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { submitPost } from "../../../../actions/video";
import "../SubmitForm.css";
import { useForm } from "react-hook-form";
import { useDispatch } from 'react-redux'
import { setSubmitFormUserInfo } from '../../../../actions/submitContentActions';
const SubmitContentUserForm = props => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {}
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = data => {
    console.log("submitting data: ", data);
    dispatch(setSubmitFormUserInfo(data));
    navigate("/quicksubmit/content");
  };

  return (
    <form
      className="register-form"
      name="submissionForm"
      onSubmit={handleSubmit(onSubmit)}
    >
      <h2 className="title"></h2>
      <div className="row">
        <div className="col-sm-6">
          <p className="form-input">
            <label htmlFor="firstName">First Name</label>
            <input
              id="first-name"
              type="text"
              name="firstName"
              placeholder="First name"
              className={"form-control"}
              {...register("firstName", { required: true })}
            />
            <span className="red-text">
              {errors.firstName?.type === "required" &&
                "First name is required"}
            </span>
          </p>
        </div>
        <div className="col-sm-6">
          <p className="form-input">
            <label htmlFor="lastName">Last Name</label>
            <input
              id="lastName"
              type="text"
              name="lastName"
              placeholder="Last name"
              className={"form-control"}
              {...register("lastName", { required: true })}
            />
            <span className="red-text">
              {errors.lastName?.type === "required" && "Last name is required"}
            </span>
          </p>
        </div>
      </div>
      <p className="form-input">
        <label htmlFor="email">Email</label>
        <input
          name="email"
          id="email"
          type="email"
          placeholder="Email"
          className={"form-control"}
          {...register("email", { required: true })}
        />
        <span className="red-text">
          {errors.email?.type === "required" && "Email is required"}
        </span>
      </p>
      <p className="form-input">
        <label htmlFor="socialType">Social Media Type</label>
        <select
          id="socialType"
          type="select"
          name="socialType"
          placeholder="Instagram"
          className={"form-control"}
          {...register("socialType", { required: true })}
        >
          <option>Instagram</option>
          <option>Youtube</option>
          <option>Vimeo</option>
          <option>Facebook</option>
        </select>
      </p>
      <p className="form-input">
        <label htmlFor="socialMediaHandle">Social Media Handle</label>
        <input
          id="socialHandle"
          type="text"
          name="socialHandle"
          placeholder=""
          className={"form-control"}
          {...register("socialhandle", { required: true })}
        />
      </p>

      <div className="btn-array">
        <p>
          <input type="submit" value={"Next"} />
        </p>
      </div>
    </form>
  );
};

SubmitContentUserForm.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(mapStateToProps, { submitPost })(SubmitContentUserForm);
