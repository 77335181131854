/* eslint-disable linebreak-style */
import PropTypes from 'prop-types';
import React from 'react';

const ImageThumbnail = (props) => {
  const { thumbnailImg } = props;
  return (
    <div className="entry-thumbnail">
      <img
        src={thumbnailImg}
        alt="Entry Thumbnail"
      />
    </div>
  );
};
ImageThumbnail.propTypes = {
  thumbnailImg: PropTypes.string,
};
ImageThumbnail.defaultProps = {
  thumbnailImg: '',
};
export default ImageThumbnail;
