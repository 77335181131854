import React from 'react';

const MediaThumbnail = () => (
  <div className="entry-thumbnail media-left">
    <img
      src="https://s3.amazonaws.com/spearchannel-content/ocean1920_1080.jpg?mw=1300&mh=562"
      alt="Entry Thumbnail"
    />
  </div>
);
export default MediaThumbnail;
