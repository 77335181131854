import React from 'react';
import { Link } from 'react-router-dom';

const ProfileVideoThumbnail = (props) => {
  const { post } = props;
  const postData = props.post;
  const defaultImg = 'https://s3.amazonaws.com/spearchannel-content/ocean1920_1080.jpg?mw=960&mh=540';
  console.log('rendering post: ', postData);
  let thumbnailImg = `https://i.ytimg.com/vi/${postData.youtube_id}/hqdefault.jpg`;

  if (!postData.youtube_id || postData.youtube_id.length == 0) {
    thumbnailImg = defaultImg;
  }

  if (postData.thumbnailImageHref) {
    thumbnailImg = postData.thumbnailImageHref;
  }
  return (
    <article className="post type-post col-sm-4">
      <div className="entry-thumbnail">
        <img
          src={thumbnailImg}
          alt="Item Thumbnail"
        />
        <a href="https://vimeo.com/201203170" className="iframe">
          <span className="play-video">
            <i className="fa fa-play-circle-o" />
          </span>
        </a>
      </div>
      <div className="entry-content">
        <h2 className="entry-title">
          <Link to={`/post/${post.id}`}>
            {post.title}
          </Link>
        </h2>
        <div className="entry-meta">
          <span>
            <i className="fa fa-clock-o" />
            {' '}
            <time dateTime="PT5M">5 months</time>
          </span>
          <span>
            <a href="/">
              <i className="fa fa-comment-o" />
              {' '}
              <span className="count">351</span>
            </a>
          </span>
          <span>
            <i className="fa fa-eye" />
            {' '}
            <span className="count">17,989</span>
          </span>
        </div>

        <p>
          Duis aute irure dolor in reprehenderit in voluptate velit esse
          cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
          cupidatat non proident, sunt in culpa qui officia deserunt mollit
          anim id est laborum. Sed ut perspiciatis unde omnis iste natus error
          sit voluptatem
        </p>
      </div>
    </article>
  );
};

export default ProfileVideoThumbnail;
