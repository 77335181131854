import React, { Component } from 'react';

class SubmitSuccessPage extends Component {
  render() {
    return (
      <section className="form-elements style-2">
        <div className="section-padding">
          We've Received Your Submission!
        </div>
      </section>
    );
  }
}

export default SubmitSuccessPage;
