/* eslint-disable react/prop-types */
/* eslint-disable linebreak-style */
import PropTypes from 'prop-types';
import React from 'react';
import VideoThumbnail from './VideoThumbnail';
import ImageThumbnail from './ImageThumbnail';
import GalleryThumbnail from './GalleryThumbnail';
import AudioThumbnail from './AudioThumbnail';
import LinkThumbnail from './LinkThumbnail';
import MediaThumbnail from './MediaThumbnail';

const PostThumbnail = (props) => {
  const postData = props.post;
  const { post } = props;
  const defaultImg = 'https://s3.amazonaws.com/spearchannel-content/ocean1920_1080.jpg?mw=960&mh=540';
  console.log('rendering post: ', postData);
  let thumbnailImg = `https://i.ytimg.com/vi/${postData.youtube_id}/hqdefault.jpg`;

  if (!postData.youtube_id || postData.youtube_id.length == 0) {
    thumbnailImg = defaultImg;
  }

  if (postData.thumbnailImageHref) {
    thumbnailImg = postData.thumbnailImageHref;
  }

  // form type-post
  const renderTypePost = () => {
    if (postData.typePostVideo === true) {
      return (<VideoThumbnail thumbnailImg={thumbnailImg} />);
    }
    return (<ImageThumbnail thumbnailImg={thumbnailImg} />);
  };

  if (post.format === 'format-gallery') {
    return (<GalleryThumbnail />);
  } if (post.format === 'format-link') {
    return (<LinkThumbnail />);
  } if (post.format === 'format-standard') {
    return (<ImageThumbnail thumbnailImg={thumbnailImg} />);
  } if (post.format === 'format-audio') {
    return (<AudioThumbnail />);
  } if (post.format === 'format-video') {
    return (<VideoThumbnail thumbnailImg={thumbnailImg} />);
  } if (
    post.format === 'type-post'
      && post.mediaPost
      && post.postPlaylist
  ) {
    return (
      <div className="entry-thumbnail media-left">
        <img
          src={thumbnailImg}
          alt="Entry Thumbnail"
        />
        <span className="post-count">
          <a href="/">
            <span className="count">19</span>
            {' '}
            videos
          </a>
        </span>
      </div>
    );
  } if (post.format === 'type-post' && post.typePostVideo) {
    return renderTypePost();
  } if (
    post.format === 'type-post'
      && post.mediaPost
      && !post.postPlaylist
  ) {
    return (<MediaThumbnail />);
  } if (
    post.format === 'type-post'
      && !post.videoPlaylistItem
      && !post.mediaPost
  ) {
    return (<ImageThumbnail thumbnailImg={thumbnailImg} />);
  } if (post.format === 'type-post' && post.videoPlaylistItem) {
    return (
      <div className="entry-thumbnail">
        <img
          src={thumbnailImg}
          alt="Entry Thumbnail"
        />
        <a href="https://vimeo.com/203743018" className="iframe">
          <span className="play-video">
            <i className="fa fa-play-circle-o" />
          </span>
        </a>
      </div>
    );
  }
};

export default PostThumbnail;
