/* eslint-disable linebreak-style */
import PropTypes from 'prop-types';
import React from 'react';

const VideoThumbnail = (props) => {
  const { thumbnailImg } = props;
  return (
    <div className="entry-thumbnail">
      <img
        src={thumbnailImg}
        alt="Item Thumbnail"
      />
      <a className="iframe" href="https://vimeo.com/203703152">
        <i className="fa fa-play" />
      </a>
    </div>
  );
};
VideoThumbnail.propTypes = {
  thumbnailImg: PropTypes.string,
};
VideoThumbnail.defaultProps = {
  thumbnailImg: '',
};
export default VideoThumbnail;
