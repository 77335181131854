import axios from 'axios';
import {
  GET_ERRORS,
  SUCCESS_POST_UPLOAD,
  SET_MY_POSTS,
  RECEIVE_ALL_VIDEOS,
  SET_CURRENT_POST,
  RECEIVE_HOMEPAGE_POSTS,
  RECEIVE_TOP_VIDEO_PLAYLISTS,
  RECEIVE_LATEST_VIDEOS,
  RECEIVE_VIRAL_VIDEOS,
} from './types';

export const uploadPost = (videoData, dispatch) => {
  console.log('uplading post data: ', videoData);

  return new Promise((resolve, reject) => {
    axios
      .post('/api/posts/upload', videoData)
      .then((res) => {
        dispatch({
          type: SUCCESS_POST_UPLOAD,
          payload: res.data,
        });
        resolve(res);
        // send signal of successful post upload
      }) // re-direct to login on successful register
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        console.log('rejecting err:', err);
        reject(err);
      });
  });
};

export const getAllPosts = (dispatch) => {
  axios
    .get('/api/posts')
    .then((res) => {
      console.log('getAllPosts response: ', res);
      // send signal of successful post
    })
    .catch((err) => dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    }));
};

export const setCurrentPost = (post) => (dispatch) => {
  dispatch({ type: SET_CURRENT_POST, post });
};

export const getPostById = (postId) => (dispatch) => new Promise((resolve, reject) => {
  axios
    .get(`/api/posts/${postId}`)
    .then((res) => {
      dispatch({ type: SET_CURRENT_POST, payload: res.data });
      resolve(res);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      console.log('rejecting err:', err);
      reject(err);
    });
});

export const getPostByAuthorId = (authorId) => (dispatch) => new Promise((resolve, reject) => {
  axios
    .get(`/api/posts/author/${authorId}`)
    .then((res) => {
      console.log(res);
      dispatch({ type: SET_MY_POSTS, payload: res.data });
      console.log('resolving promise: ', res.data);
      resolve(res);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      console.log('rejecting err:', err);
      reject(err);
    });
});

export const getAllVideos = () => (dispatch) => new Promise((resolve, reject) => {
  axios
    .get('/api/posts/videos')
    .then((res) => {
      dispatch({ type: RECEIVE_ALL_VIDEOS, payload: res.data });
      resolve(res);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      console.log('rejecting err:', err);
      reject(err);
    });
});

export const getHomePageVideos = () => (dispatch) => new Promise((resolve, reject) => {
  axios
    .get('/api/posts/videos')
    .then((res) => {
      dispatch({ type: RECEIVE_HOMEPAGE_POSTS, payload: res.data });
      console.log('resolving promise: ', res.data);
      resolve(res);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      console.log('rejecting err:', err);
      reject(err);
    });
});

export const getVideoPlaylists = () => (dispatch) => new Promise((resolve, reject) => {
  axios
    .get('/api/posts/videos')
    .then((res) => {
      dispatch({ type: RECEIVE_TOP_VIDEO_PLAYLISTS, payload: res.data });
      console.log('resolving promise: ', res.data);
      resolve(res);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      console.log('rejecting err:', err);
      reject(err);
    });
});

export const getLatestVideos = () => (dispatch) => new Promise((resolve, reject) => {
  axios
    .get('/api/posts/videos')
    .then((res) => {
      dispatch({ type: RECEIVE_LATEST_VIDEOS, payload: res.data });
      console.log('resolving promise: ', res.data);
      resolve(res);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      console.log('rejecting err:', err);
      reject(err);
    });
});

export const getViralVideos = () => (dispatch) => new Promise((resolve, reject) => {
  axios
    .get('/api/posts/videos')
    .then((res) => {
      dispatch({ type: RECEIVE_VIRAL_VIDEOS, payload: res.data });
      console.log('resolving promise: ', res.data);
      resolve(res);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      console.log('rejecting err:', err);
      reject(err);
    });
});
