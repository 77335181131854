import React from 'react';
import HomePageCollage from './HomePageCollage';
import VideoPlaylistsSection from './VideoPlaylistsSection';
import HomePageSection from './HomePageSection';

const HomePage = () => (
  <div>
    <HomePageCollage />
    <VideoPlaylistsSection />
    <HomePageSection />
  </div>
);

export default HomePage;
