/* eslint-disable linebreak-style */
import React from 'react';
import PostContent from './PostContent';
import PostThumbnail from './PostThumbnail';
import ProfileVideoThumbnail from './ProfileVideoThumbnail';

const Post = (props) => {
  const { post } = props;
  if (post.type === 'postprofilevideo') {
    return (<ProfileVideoThumbnail post={post} />);
  }

  return (
    <article className={`post type-post ${post.format}`}>
      <PostThumbnail post={post} />
      <PostContent post={post} />
    </article>
  );
};
export default Post;
