import React from 'react';

const GalleryThumbnail = () => (
  <div className="entry-thumbnail">
    <div id="post-slider" className="post-slider carousel slide">
      <div className="carousel-inner">
        <div className="item active">
          <img
            src="https://s3.amazonaws.com/spearchannel-content/ocean1920_1080.jpg?mw=1920&mh=1080"
            alt="Entry Thumbnail"
          />
        </div>
        <div className="item">
          <img
            src="https://s3.amazonaws.com/spearchannel-content/ocean1920_1080.jpg?mw=1920&mh=1080"
            alt="Entry Thumbnail"
          />
        </div>
        <div className="item">
          <img
            src="https://s3.amazonaws.com/spearchannel-content/ocean1920_1080.jpg?mw=1920&mh=1080"
            alt="Entry Thumbnail"
          />
        </div>
      </div>
      <a
        className="left carousel-control"
        href="#post-slider"
        role="button"
        data-slide="prev"
      >
        <i className="fa fa-angle-left fa-6" aria-hidden="true" />
      </a>

      <a
        className="right carousel-control"
        href="#post-slider"
        role="button"
        data-slide="next"
      >
        <i className="fa fa-angle-right fa-6" aria-hidden="true" />
      </a>
    </div>
  </div>
);
export default GalleryThumbnail;
