// eslint-disable-next-line jsx-a11y/label-has-associated-control
import {
  SET_FORM_STAGE, SET_FORM_COMPLETION, SET_FORM_CONTENT_UPLOAD, SET_FORM_USER_INFO,
} from '../actions/types';

const initialState = {
  FormStage: 1, // default page stage to show on page load
  FormCompletion: {},
  FormUserInfo: {},
  FormContentUpload: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    // eslint-disable-next-line indent
    case SET_FORM_STAGE:
      return {
        ...state,
        FormStage: (action.payload),
      };
    case SET_FORM_COMPLETION:
      return {
        ...state,
        FormCompletion: (action.payload),
      };
    case SET_FORM_CONTENT_UPLOAD:
      return {
        ...state,
        FormContentUpload: (action.payload),
      };
    case SET_FORM_USER_INFO:
      return {
        ...state,
        FormUserInfo: (action.payload),
      };
    default:
      return state;
  }
}
