export const GET_ERRORS = 'GET_ERRORS';
export const USER_LOADING = 'USER_LOADING';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const RECEIVE_ALL_POSTS = 'RECEIVE_ALL_POSTS';
export const REQUEST_POSTS_PAGE = 'REQUEST_POSTS_PAGE';
export const SUCCESS_POST_UPLOAD = 'SUCCESS_POST_UPLOAD';
export const FAIL_POST_UPLOAD = 'FAIL_POST_UPLOAD';

// Toast Actions
export const TOAST_SUCCESS = 'TOAST_SUCCESS';
export const TOAST_FAILURE = 'TOAST_FAILURE';

// Posts Actions
export const SET_MY_POSTS = 'SET_MY_POSTS';
export const RECEIVE_ALL_VIDEOS = 'RECEIVE_ALL_VIDEOS';
export const SET_CURRENT_POST = 'SET_CURRENT_POST';
export const RECEIVE_HOMEPAGE_POSTS = 'RECEIVE_HOMEPAGE_POSTS';
export const RECEIVE_TOP_VIDEO_PLAYLISTS = 'RECEIVE_TOP_VIDEO_PLAYLISTS';
export const RECEIVE_LATEST_VIDEOS = 'RECEIVE_LATEST_VIDEOS';
export const RECEIVE_VIRAL_VIDEOS = 'RECEIVE_VIRAL_VIDEOS';

// Content Upload Actions
export const SET_FORM_STAGE = 'SET_FORM_STAGE';
export const SET_FORM_COMPLETION = 'SET_FORM_COMPLETION';
export const SET_FORM_CONTENT_UPLOAD = 'SET_FORM_CONTENT_UPLOAD';
export const SET_FORM_USER_INFO = 'SET_FORM_USER_INFO';
export const SUCCESS_SUBMISSION_UPLOAD = 'SUCCESS_POST_UPLOAD';
