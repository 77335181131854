import React from 'react';
import Post from '../posts/Post';
import SideBar
  from '../SideBar';

const HomePageSection = () => {
  const VideoPost1 = {
    format: 'format-standard',
    title: 'Spearfishing in Paradise',
    videoUploadDate: '3 days',
    commentCount: 569,
    viewCount: 1744,
    postDescriptionShort:
          'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem',
    thumbnailImageHref: '',
  };
  return (
    <section className="video-contents">
      <div className="section-padding">
        <div className="container">
          <div className="row">
            <div className="col-sm-8">
              <h2 className="section-title">Latest Videos</h2>
              <div className="latest-videos-slider owl-carousel owl-theme">
                <div className="item">
                  <Post post={VideoPost1} />
                  <Post post={VideoPost1} />
                </div>
                <div className="item">
                  <Post post={VideoPost1} />
                  <Post post={VideoPost1} />
                </div>
                <div className="item">
                  <Post post={VideoPost1} />
                  <Post post={VideoPost1} />
                </div>
                <div className="item">
                  <Post post={VideoPost1} />
                  <Post post={VideoPost1} />
                </div>
              </div>

              <div className="padding" />

              <h2 className="section-title">Viral Videos</h2>

              <div className="viral-videos-slider owl-carousel owl-theme">
                <div className="item">
                  <Post post={VideoPost1} />
                  <Post post={VideoPost1} />
                </div>

                <div className="item">
                  <Post post={VideoPost1} />
                  <Post post={VideoPost1} />
                </div>

                <div className="item">
                  <Post post={VideoPost1} />
                  <Post post={VideoPost1} />
                </div>

                <div className="item">
                  <Post post={VideoPost1} />
                  <Post post={VideoPost1} />
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <SideBar />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomePageSection;
