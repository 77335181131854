import React from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { submitPost } from '../../../../actions/video';
import '../SubmitForm.css';
import {
  showSuccessAction,
  showFailureAction,
} from '../../../../actions/toastActions';
import { uploadSubmittedContent } from '../../../../actions/submitContentActions';

const SubmitContentCompletionForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });
  const navigate = useNavigate();
  const submissionUserInfo = useSelector((state) => state.signUpReducer.FormUserInfo);
  const submissionContentUpload = useSelector((state) => state.signUpReducer.FormContentUpload);
  const compileSubmissionFormData = (completionData) => ({
    title: 'Online Submission',
    first_name: submissionUserInfo.firstName,
    last_name: submissionUserInfo.lastName,
    email: submissionUserInfo.email,
    user_name: submissionUserInfo.socialHandle,
    description: completionData.description,
    content_url: submissionContentUpload.videoLink,
  });

  const onSubmit = (completionData) => {
    console.log('submitting completion data: ', completionData);
    const formData = compileSubmissionFormData(completionData);
    uploadSubmittedContent(formData)
      .then((res) => {
        console.log('showing success action', res);
        navigate('/quicksubmit/success');
      })
      .catch((error) => {
        console.log('catching error:', error);
        navigate('/quicksubmit/error');
      });
  };

  /* useEffect(() => {
    window.scrollTo(0, 0);
  }, []); */

  return (
    <form
      className="register-form"
      name="submissionForm"
      onSubmit={handleSubmit(onSubmit)}
    >
      <h2 className="title">Almost done!</h2>
      <p className="form-input">
        <label htmlFor="socialType">Tags</label>
        <select
          id="socialType"
          type="select"
          name="socialType"
          placeholder="Instagram"
          className="form-control"
          {...register('socialType', { required: true })}
        >
          <option>Freediving</option>
          <option>Bluewater</option>
          <option>Shorediving</option>
          <option>Reef</option>
        </select>
      </p>
      <p className="form-input">
        <label htmlFor="socialMediaHandle">Description</label>
        <input
          id="socialHandle"
          type="text"
          name="socialHandle"
          placeholder=""
          className="form-control"
          {...register('socialhandle', { required: true })}
        />
      </p>

      <div className="btn-array">
        <p>
          <input type="submit" value="Submit Content" />
        </p>
      </div>
    </form>
  );
};

SubmitContentCompletionForm.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});
export default connect(mapStateToProps, { submitPost })(SubmitContentCompletionForm);
