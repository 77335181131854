import axios from 'axios';
import {
  SUCCESS_SUBMISSION_UPLOAD, SET_FORM_USER_INFO, SET_FORM_CONTENT_UPLOAD, SET_FORM_COMPLETION,
} from './types';

export const setSubmitFormUserInfo = (data) => (dispatch) => {
  dispatch({ type: SET_FORM_USER_INFO, payload: data });
};

export const setSubmitFormContentUpload = (data) => (dispatch) => {
  dispatch({ type: SET_FORM_CONTENT_UPLOAD, payload: data });
};

export const setSubmitFormCompletion = (data) => (dispatch) => {
  dispatch({ type: SET_FORM_COMPLETION, payload: data });
};

export const uploadSubmittedContent = (videoData, dispatch) => {
  console.log('uploading submitted content', videoData);
  return new Promise((resolve, reject) => {
    axios
      .post('/api/submissions', videoData)
      .then((res) => {
        resolve(res);
        // send signal of successful post upload
      }) // re-direct to login on successful register
      .catch((err) => {
        /* dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        }); */
        console.log('rejecting err:', err);
        reject(err);
      });
  });
};
