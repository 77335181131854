import React, { Component } from 'react';
import { Outlet } from 'react-router-dom';

class SubmitVideoPage extends Component {
  render() {
    return (
      <section className="blog-posts">
        <div className="section-padding">
          <div className="container">
            <div className="form-elements style-2">
              <div className="section-padding">
                <div className="right-panel">
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default SubmitVideoPage;
