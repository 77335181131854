import React, { Component } from 'react';
import Post from '../posts/Post';

class LatestPostWidget extends Component {
  render() {
    const VideoPost1 = {
      format: 'type-post',
      title: 'Spearfishing in Paradise',
      videoUploadDate: '3 days',
      commentCount: 569,
      viewCount: 1744,
      postDescriptionShort:
        'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem',
      thumbnailImageHref: '',
      mediaPost: true,
    };

    const VideoPost2 = {
      format: 'type-post',
      title: 'Spearfishing in Paradise',
      videoUploadDate: '3 days',
      commentCount: 569,
      viewCount: 1744,
      postDescriptionShort:
        'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem',
      thumbnailImageHref: '',
      mediaPost: true,
    };

    const VideoPost3 = {
      format: 'type-post',
      title: 'Spearfishing in Paradise',
      videoUploadDate: '3 days',
      commentCount: 569,
      viewCount: 1744,
      postDescriptionShort:
        'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem',
      thumbnailImageHref: '',
      mediaPost: true,
    };

    return (
      <div className="widget widget_latest_post">
        <h3 className="widget-title">Latest Post</h3>
        <div className="widget-details">
          <Post post={VideoPost1} />
          <Post post={VideoPost1} />
          <Post post={VideoPost1} />
          <Post post={VideoPost1} />
        </div>
      </div>
    );
  }
}

export default LatestPostWidget;
