import React from 'react';
import { Link } from 'react-router-dom';

// inside post content of homepage urls
const PostContent = (props) => {
  const { post } = props;
  const postId = post.id;
  const postUrl = `/post/${postId}`;
  if (
    post.format === 'type-post'
      && !post.videoPlaylistItem
      && !post.mediaPost
  ) {
    return (
      <div className="entry-content">
        <span>
          <a href="/" className="category">
            Spearfishing
          </a>
        </span>
        <h2 className="entry-title">
          <Link
            to={{
              pathname: '/post/single-post',
              state: {
                pageType: 'single-post',
                post,
              },
            }}
          >
            {post.title}
          </Link>
        </h2>
        <div className="entry-meta">
          <span className="author">
            <a href="/">{post.author}</a>
          </span>
          <span>
            <i className="fa fa-clock-o" />
            {' '}
            <time dateTime="PT5M">
              {post.time}
              {' '}
              months
            </time>
          </span>
          <span>
            <a href="/">
              <i className="fa fa-comment-o" />
              {' '}
              <span className="count">{post.commentCount}</span>
            </a>
          </span>
          <span>
            <i className="fa fa-eye" />
            {' '}
            <span className="count">{post.viewCount}</span>
          </span>
        </div>
      </div>
    );
  } if (post.format === 'type-post' && post.videoPlaylistItem) {
    return (
      <div className="entry-content">
        <h3 className="category-name">
          <a href="/">Spearfishing</a>
        </h3>
        <span className="post-count">
          <a href="/">
            <span className="count">19</span>
            {' '}
            videos
          </a>
        </span>
      </div>
    );
  } if (
    post.format === 'type-post'
      && post.mediaPost
      && !post.postPlaylist
  ) {
    return (
      <div className="entry-content media-body">
        <h3 className="entry-title">
          <Link
            to={{
              pathname: 'post/single-post',
              state: {
                pageType: 'single-post',
              },
            }}
          >
            {post.title}
          </Link>
        </h3>
        <div className="entry-meta">
          <span>
            <i className="fa fa-clock-o" />
            {' '}
            <time dateTime="2017-01-20">20 Jan, 2017</time>
          </span>
        </div>
      </div>
    );
  } if (
    post.format === 'type-post'
      && post.mediaPost
      && post.postPlaylist
  ) {
    return (
      <div className="entry-content media-body">
        <h2 className="entry-title">Diver Video Playlist</h2>
        <ul className="list-tracks">
          <li>
            <a href="/">
              Spearfishing Video 1 ...........
              {' '}
              <span className="deuration">5:21</span>
            </a>
          </li>
          <li>
            <a href="/">
              Spearfishing Video 2............
              {' '}
              <span className="deuration">9:21</span>
            </a>
          </li>
          <li>
            <a href="/">
              Spearfishing Video 3 ..........
              {' '}
              <span className="deuration">7:21</span>
            </a>
          </li>
          <li>
            <a href="/">
              Spearfishing Video 4 ............
              {' '}
              <span className="deuration">11:21</span>
            </a>
          </li>
        </ul>
        <a href="/" className="btn">
          View full playlist (9 videos)
        </a>
      </div>
    );
  }
  return (
    <div className="entry-content">
      <h2 className="entry-title">
        <a href="single-post.html">
          {post.title}
        </a>
      </h2>
      <div className="entry-meta">
        <span>
          <i className="fa fa-clock-o" />
          {' '}
          <time dateTime="PT5M">{post.videoUploadDate}</time>
        </span>
        <span>
          <a href="/">
            <i className="fa fa-comment-o" />
            {' '}
            <span className="count">{post.commentCount}</span>
          </a>
        </span>
        <span>
          <i className="fa fa-eye" />
          {' '}
          <span className="count">{post.viewCount}</span>
        </span>
      </div>
      <p className="description">{post.postDescriptionShort}</p>
      <Link to={postUrl} className="btn">
        Continue Reading
      </Link>
    </div>
  );
};

export default PostContent;
