/* eslint-disable linebreak-style */
import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getPostById } from '../../actions/postActions';
import PostPagination from './postpage/PostPagination';
import PostComments from './postpage/PostPageComments';
import SideBar from '../SideBar';
import {
  showSuccessAction,
  showFailureAction,
} from '../../actions/toastActions';

const PostPage = (props) => {
  const post = useSelector((state) => state.currentPost);

  console.log(props);
  const { postId } = useParams();
  console.log('postId: ', postId);
  useEffect(
    (postId) => {
      props
        .getPostById(postId)
        .then((res) => {
          console.log('post by id: ', res);
        })
        .catch((error) => {
          console.log('post by id error: ', error);
        });
    },
    [props.getPostById],
  );

  const state = {
    showComments: false,
  };
  return (
    <section className="blog-posts single-post">
      <div className="section-padding">
        <div className="container">
          <div className="row">
            <div className="col-sm-8">
              <article className="post type-post format-standard">
                <div className="entry-thumbnail">
                  <img
                    src="https://s3.amazonaws.com/spearchannel-content/ocean1920_1080.jpg?mw=1920&mh=1080"
                    alt="Entry Thumbnail"
                  />
                </div>
                <div className="entry-content">
                  <h2 className="entry-title">
                    <a href="blog-single.html">{post.title}</a>
                  </h2>
                  <div className="entry-meta">
                    <span>
                      <i className="fa fa-clock-o" />
                      {' '}
                      <time dateTime="PT5M">5 months</time>
                    </span>
                    <span>
                      <a href="/">
                        <i className="fa fa-comment-o" />
                        {' '}
                        <span className="count">{post.commentCount}</span>
                      </a>
                    </span>
                    <span>
                      <i className="fa fa-eye" />
                      {' '}
                      <span className="count">{post.viewCount}</span>
                    </span>
                  </div>
                  <p>
                    <strong>
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident. Sed ut perspiciatis
                      unde omnis iste natus error sit voluptatem accusantium
                    </strong>
                    <br />
                    Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut
                    odit aut fugit, sed quia consequuntur magni dolores eos qui
                    ratione voluptatem sequi nesciunt. Neque porro quisquam est,
                    qui dolorem ipsum quia dolor sit amet, consectetur, adipisci
                    velit, sed quia non numquam eius modi tempora incidunt ut
                    labore et dolore magnam aliquam quaerat voluptatem.
                  </p>
                  <blockquote>
                    “Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.”
                    <span className="name">- Source Name</span>
                  </blockquote>
                  <p>
                    Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut
                    odit aut fugit, sed quia consequuntur magni dolores eos qui
                    ratione voluptatem sequi nesciunt. Neque porro quisquam est,
                    qui dolorem ipsum quia dolor sit amet, consectetur, adipisci
                    velit, sed quia non numquam eius modi tempora incidunt ut
                    labore et dolore magnam aliquam quaerat voluptatem.
                  </p>
                </div>

                <div className="post-bottom">
                  <div className="tags pull-left">
                    <a href="/">Bluewater </a>
                    <a href="/">Travel</a>
                    <a href="/">Spearfishing</a>
                  </div>

                  <div className="post-social pull-right">
                    <button className="share-btn">
                      Share
                      {' '}
                      <i className="fa fa-share-alt" />
                    </button>
                    <div className="btn-hover">
                      <a className="facebook" href="/">
                        <i className="fa fa-facebook-square" />
                        {' '}
                        <span className="count">09</span>
                      </a>
                      <a className="pinterest" href="/">
                        <i className="fa fa-pinterest" />
                        {' '}
                        <span className="count">09</span>
                      </a>
                      <a className="twitter" href="/">
                        <i className="fa fa-twitter-square" />
                        {' '}
                        <span className="count">09</span>
                      </a>
                      <a className="google" href="/">
                        <i className="fa fa-google-plus-square" />
                        {' '}
                        <span className="count">09</span>
                      </a>
                    </div>
                  </div>
                </div>
              </article>
              {state.showComments && <PostPagination />}
              {state.showComments && <PostComments />}
            </div>

            <div className="col-sm-4">
              <SideBar />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => ({
  errors: state.errors,
});

export default connect(mapStateToProps, {
  getPostById,
  showSuccessAction,
  showFailureAction,
})(PostPage);
