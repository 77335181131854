import React from 'react';

const AudioThumbnail = () => (
  <div className="entry-thumbnail">
    <iframe
      className="post-audio"
      title="PostAudioTitle"
      src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/161730338&amp;"
    />
  </div>
);
export default AudioThumbnail;
