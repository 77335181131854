import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

// import routes
import HomePage from './homepage/HomePage';
import Profile from './profile/Profile';
import ProfilePostUpload from './profile/ProfilePostUpload';
import Register from './auth/Register';
import Login from './auth/Login';
import PrivateRoute from './private-route/PrivateRoute';
import PostPage from './posts/PostPage';
import VideoPostPage from './videos/VideoPostPage';
import Dashboard from './dashboard/Dashboard';
import Podcasts from './podcasts/Podcasts';
import Blog from './blog/Blog';
import Contact from './Contact';
import Videos from './videos/Videos';
import Charters from './charters/Charters';
import ComingSoon from './comingsoon/ComingSoon';
import SubmitVideoPage from './submit/SubmitVideoPage';
import SubmitContent from './submit/SubmitContentForm/SubmitContentUpload';
import SubmitUserInfo from './submit/SubmitContentForm/SubmitUserInfo';
import SubmitCompletion from './submit/SubmitContentForm/SubmitCompletion';
import SubmitError from './submit/SubmitContentForm/SubmitError';
import SubmitSuccess from './submit/SubmitContentForm/SubmitSuccess';
import PostList from './posts/PostList';
import VideosTwo from './VideoListTwo';
import ProfilePlaylist from './profile/ProfilePlaylist';
import ProfileVideos from './profile/ProfileVideos';
import ProfileHome from './profile/ProfileHome';
import ProfileDetails from './profile/ProfileDetails';
import ProfileEdit from './profile/ProfileEdit';
import HawaiiSkinDiver from './HawaiiSkinDiver';
import Magazine from './Magazine/Magazine';

// css
import 'react-toastify/dist/ReactToastify.css';
import '../App.css';
import SubmitSuccessPage from './submit/SubmitSuccessPage';

const Main = () => (
  <main>
    <Routes>
      <Route exact path="/" element={<HomePage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/profile" element={<Profile />}>
        <Route path="/profile/details" element={<ProfileDetails />} />
        <Route path="/profile/home" element={<ProfileHome />} />
        <Route path="/profile/videos" element={<ProfileVideos />} />
        <Route path="/profile/playlist" element={<ProfilePlaylist />} />
        <Route path="/profile/upload" element={<ProfilePostUpload />} />
        <Route path="/profile/edit" element={<ProfileEdit />} />
      </Route>
      <Route path="/videoupload" element={<ProfilePostUpload />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/comingsoon" element={<ComingSoon />} />
      <Route path="/videos" element={<Videos />} />
      <Route path="/post/:postId" element={<PostPage />} />
      <Route path="/videopost/:postId" element={<VideoPostPage />} />
      <Route path="/charters" element={<Charters />} />
      <Route path="/podcasts" element={<Podcasts />} />
      <Route path="/quicksubmit" element={<SubmitVideoPage />}>
        <Route path="/quicksubmit/userinfo" element={<SubmitUserInfo />} />
        <Route path="/quicksubmit/content" element={<SubmitContent />} />
        <Route path="/quicksubmit/completion" element={<SubmitCompletion />} />
        <Route path="/quicksubmit/success" element={<SubmitSuccess />} />
        <Route path="/quicksubmit/error" element={<SubmitError />} />
      </Route>
      <Route path="/submissionsuccess" element={<SubmitSuccessPage />} />
      <Route path="/posts" element={<PostList />} />
      <Route path="/videosTwo" element={<VideosTwo />} />
      <Route path="/hawaiiSkinDiver" element={<HawaiiSkinDiver />} />
      <Route path="/magazine/:magazineId" element={<Magazine />} />
      <Route path="/dashboard" element={<PrivateRoute />}>
        <Route exact path="/dashboard" element={<Dashboard />} />
      </Route>
    </Routes>
    <ToastContainer autoClose={2000} />
  </main>
);

export default Main;
