import { toast } from 'react-toastify';
import { TOAST_SUCCESS, TOAST_FAILURE } from './types';

export const showSuccessAction = (message) => (dispatch) => {
  console.log('showing success action');
  dispatch({
    type: TOAST_SUCCESS,
  });
  toast.success(message);
};

export const showFailureAction = (message) => (dispatch) => {
  console.log('showing failure action');
  dispatch({
    type: TOAST_FAILURE,
  });
  toast.error(message);
};
