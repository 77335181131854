import { combineReducers } from 'redux';
import authReducer from './authReducer';
import errorReducer from './errorReducer';
import currentPostReducer from './currentPostReducer';
import reducer from './signUpReducer';
import posts from './posts/posts';

export default combineReducers({
  currentPost: currentPostReducer,
  auth: authReducer,
  posts,
  errors: errorReducer,
  signUpReducer: reducer,
});
